import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiListResponse, ApiResponse } from '../models/ApiResponse';
import { I18nObject } from '../models/I18n.model';
import { OrgName, RecordCreationMetadata } from '../models/System.model';

export interface NetworkResponse {
	id: number;
	orgId: number;
	name: I18nObject;
	description: I18nObject;
	active: boolean;
	startDate: number | null;
	endDate: number | null;
	memberCount: number;
	owner: {
		id: number;
		name: OrgName;
	};
	role: 'owner' | 'member';
	_created: RecordCreationMetadata;
}

@Injectable({
	providedIn: 'root'
})
export class ProfileService {
	baseURL = `${environment.baseService}${environment.services.cord}`;

	constructor(private http: HttpClient) {
	}

	getMyInitiatives(): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/my/initiatives`);
	}

	getMyCampaigns(initiativeId?: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/my/campaigns${initiativeId !== undefined ? ('?initiativeId=' + initiativeId) : ''}`);
	}

	getMyOrgs(): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/my/orgs`);
	}

	getMyPermissions(): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/my/permissions`);
	}

	getMyNetworks(): Observable<ApiListResponse<NetworkResponse>> {
		return this.http.get<ApiListResponse<NetworkResponse>>(`${this.baseURL}/my/networks`);
	}

	//Get config of org the logged-in user
	getOrgConfig(): Observable<any> {
		return this.http.get<any>(`${this.baseURL}/my/config`);
	}

	// Update config of org the logged-in user
	updateUserOrgConfig(type: 'full' | 'legal' | 'org'): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/my/config/${type}`, {});
	}
}


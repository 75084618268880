import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		// angular bootstrap component
		AppComponent,
	],
	imports: [
		// angular
		BrowserModule,
		BrowserAnimationsModule,
		MatInputModule,
		MatFormFieldModule,
		MatRadioModule,
		MatButtonModule,
		MatSelectModule,

		// core & shared
		CoreModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				// useClass: I18nService,
				deps: [HttpClient]
			},
			defaultLanguage: 'en',
			useDefaultLang: true
		}),

		// app
		AppRoutingModule,

		// 3rd party
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {
}
